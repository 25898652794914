
@each $color, $value in $colors {
    .bg-gradient-#{$color} {
      background: linear-gradient(90deg,$value,darken($value,8),darken($value,15)) !important;
    }
}

@each $color, $value in $theme-colors {
    .bg-gradient-#{$color} {
      background: linear-gradient(90deg,$value,darken($value,8),darken($value,15)) !important;
    }
}

  

.icon-shape {
    padding: 12px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.icon {
    width: 3rem;
    height: 3rem;
    font-size: 1.3rem;
}