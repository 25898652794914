//
// _nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: $gray-800;
      font-weight:  $font-weight-semibold;
    }
  }
}


.nav-pills .nav-item:not(.nav-item-horizontal)  .nav-link.active, .nav-pills .nav-item:not(.nav-item-horizontal)  .show > .nav-link{
  border-left: 3px solid $primary;
  color: darken($primary,10%);
  border-radius:0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.nav-pills .nav-item-horizontal .nav-link.active, .nav-pills .nav-item-horizontal .show > .nav-link{
  border-bottom: 3px solid $primary;
  color: darken($primary,10%);
    border-radius:0px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.nav-pills {
  > a {
    color: $gray-900;
    font-weight: $font-weight-semibold;
  }
}

.nav-tabs-custom {
  border-bottom: 2px solid $gray-300;

  .nav-item {
    position: relative;
    color: $dark;
    .nav-link {
      border: none;

      &::after {
        content: "";
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: $primary;

        &:after {
          transform: scale(1);
        }
      }
    }
  }
}

// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;
      .nav-icon {
        font-size: 24px;
      }
    }
  }
}

.sidebar{
  width: $sidebar-width;
}



.navbar{
  height: 70px !important;
}


