@import "./bootstrap.scss";


.header-title{
    font-weight: 500;
    font-size: 22px;
    color: rgb(160, 160, 160);
}




.text-sm{
  font-size: .875rem!important;
}

.text-xs{
  font-size: .750rem!important;
}


  .searchbar{

    border-radius: 30px;
 
    
  }

  .search_input{
    color: white;
    border: 0;
    outline: 0;
    background: none;
    width: 0;
    padding: 0;
    margin-left: 10px;
    padding-left: 20px;
    background-color: lighten($color:$primary, $amount: 25);
    transition-property:  width;
    transition-duration:  0.6s;

    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }

  

  .search_input::placeholder {
    color: rgba(255,255,255,0.5);

  }

  .searchbar:hover > .search_input, .searchbar > .search_input:not(:placeholder-shown), .searchbar > .search_input:focus{

  
    width: 100%;
    //transition: width .6s linear;
  
    //transition-property: background-color, width;
    //transition-duration: .6s;
   
   

  }

  .searchbar:hover > .search_input, .searchbar > .search_input:not(:placeholder-shown), .searchbar > .search_input:focus{

  
    width: 100%;   

  }







  



 

  .search_icon{
    background-color: lighten($color:$primary, $amount: 25);
    margin-left: -20px;
    border:none;
    font-size: 1.2rem;
    color:$primary;
    border-radius: 100%;
    
  }


  @keyframes myAnimation3 {
    from {transform:translateY(-100%);}
    to {transform:translateY(0);}
  }
  @-moz-keyframes myAnimation3 {
    from {transform:translateY(-100%);}
    to {transform:translateY(0);}
  }
  @-webkit-keyframes myAnimation3 {
    from {transform:translateY(-100%);}
    to {transform:translateY(0);}
  }
  @-o-keyframes myAnimation3 {
    from {transform:translateY(-100%);}
    to {transform:translateY(0);}
  }



  .header-v1 {
  
    

    &.menu-scroll-v1 {
        animation: myAnimation3 1s ease;
        //background-color:lightgray !important;
       
        z-index: 1;
        position: fixed;
      
  
        top: 0;
     
        padding-right: 0;


    }
    @media (min-width: map-get($grid-breakpoints, "md")) {
      &.menu-scroll-v1{
        
        padding-right: $sidebar-width;
      }
    }
   
  
  }

  .content-scrolled{
    margin-top: 70px;
  }


  .main-sidebar{
 
    margin-left: -$sidebar-width;


    -webkit-transition:  margin-left 0.8s;
    -moz-transition: margin-left 0.8s;
    -o-transition: margin-left 0.8s;
    transition:  margin-left 0.8s;
  }

  @media (min-width: map-get($grid-breakpoints, "md")) {
    .main-sidebar{

      margin-left: 0;
    }
  }

  .main-content-div{
    margin-left: 0;
 


    -webkit-transition:  margin-left 0.8s;
    -moz-transition: margin-left 0.8s;
    -o-transition: margin-left 0.8s;
    transition:  margin-left 0.8s;
  }
  @media (min-width: map-get($grid-breakpoints, "md")) {
    .main-content-div{
      margin-left: $sidebar-width;
    }
  }

  .row [class*='col-'] {
  
    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
}



.fade-enter {


  opacity: 0;
}

.fade-enter-active {

  //opacity: 1;
  transform: scale(0.95);
  //transition: all 250ms  ease-in;
  //transition-delay: 300ms;

}


.fade-exit {
  opacity: 1;


}
.fade-exit-active {
  

  position: absolute;
  top: 0;
  transform: scale(0.95);
  
  opacity: 0;
  transition: all 300ms ease-out;

}

.fade-enter-done{
  opacity: 1;
  transform: scale(1);
  position:inherit;
  transition: all 250ms  ease-in;


}



.inner-shadow{

	box-shadow: 0 0 12px rgba(0, 0, 0, 0.2) inset; 
	-webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.2) inset; 
	-moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.2) inset; 
}

.elevated{

  border-top: 0.25px solid #e3e3e9;

    border-left: 0.25px solid #e3e3e9;
    border-right: 0.25px solid #e3e3e9;
    border-bottom: 0.25px solid #e3e3e9;
    box-shadow: $box-shadow;
  
}

.card{
  border-radius: 0.25rem !important;


}

.card-body{
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;

}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.react-pdf__Page__canvas{
  max-width: 100%;
  height: auto !important;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
   -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
       animation: fadein 2s;
}




.card-flip {
  display: block;
  opacity: 1;
 
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  
}
 
.card-flip.hidden{
  display: none;
  opacity: 0;
}

.content-scrolled > .menu-scroll-v1{
  @extend .shadow-lg;
}