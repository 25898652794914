//
// Forms.scss
//

// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: 1.25rem;
  .form-check-input {
    right: 0;
    margin-left: 0;
  }

  .form-check-label {
    display: block;
  }
}

.custom-control-right {
  padding-left: 0;
  padding-right: 1.5rem;
  display: inline-block;
  .custom-control-label {
    display: inline-block;
    &:before,
    &:after {
      left: auto;
      right: -1.5rem;
    }
  }

  .custom-control-input {
    left: auto;
  }
}

// checkbox

.custom-checkbox-outline {
  .custom-control-label::before {
    border-width: 2px;
  }

  .custom-control-input {
    &:checked ~ .custom-control-label {
      &:after {
        background-image: none;
        content: "\f00c";
        font-family: "Font Awesome";
        font-size: 20px;
        top: -8px;
        left: -22px;
      }

      &:before {
        background-color: transparent !important;
      }
    }
  }
}

// radio

.custom-radio-outline {
  .custom-control-label::before {
    border-width: 2px;
  }

  .custom-control-input {
    &:checked ~ .custom-control-label {
      &:after {
        background-image: none;
        content: "\f00c";
        font-weight:"900";
        font-family: "Font Awesome 5 Free Solid";
        font-size: 8px;
        top: 4px;
        left: -20px;
        src: url('../../../fonts/fa-solid-900.eot') format('embedded-opentype'),url('../../../fonts/fa-solid-900.woff2') format('woff2'),url('../../../fonts/fa-solid-900.woff') format('woff'),url('../../../fonts/fa-solid-900.ttf') format('truetype'),url('../../../fonts/fa-solid-900.svg') format('svg');
        src: url('../../../fonts/fa-solid-900.eot');
      }

      &:before {
        background-color: transparent !important;
      }
    }
  }
}

// checkbox color

@each $color, $value in $theme-colors {
  .custom-checkbox-#{$color},
  .custom-radio-#{$color} {
    .custom-control-input {
      &:checked ~ .custom-control-label:before {
        background-color: $value;
        border-color: $value;
      }
    }
  }

  .custom-radio-outline {
    &.custom-radio-#{$color} {
      .custom-control-input {
        &:checked ~ .custom-control-label:after {
          color: $value;
        }
      }
    }
  }
}

.custom-checkbox-dark,
.custom-radio-dark {
  .custom-control-input {
    &:checked ~ .custom-control-label:before {
      color: $dark;
    }
  }
}

.custom-control-label {
  cursor: pointer;
}

// Switch sizes

.custom-switch-md {
  padding-left: 3rem;
  .custom-control-label {
    line-height: 20px;
    &:before {
      width: 40px;
      height: 20px;
      border-radius: 30px;
      left: -3rem;
    }
    &:after {
      width: calc(20px - 4px);
      height: calc(20px - 4px);
      left: calc(-3rem + 2px);
    }
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.25rem);
  }
}

.custom-switch-lg {
  padding-left: 3.75rem;
  .custom-control-label {
    line-height: 24px;
    &:before {
      width: 48px;
      height: 24px;
      border-radius: 30px;
      left: -3.75rem;
    }
    &:after {
      width: calc(24px - 4px);
      height: calc(24px - 4px);
      left: calc(-3.75rem + 2px);
      border-radius: 50%;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.5rem);
  }
}

.custom-control-label::before {
  background-color: $input-bg;
}

.form-floating > label{
  margin-top: 3px
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label{
  text-decoration: underline;
  opacity: 0.5;
}

.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}
.files input:focus{     outline: 2px dashed #92b0b3;  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #92b0b3;
}
.files{ position:relative}
.files:after {  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f093";  
  display: block;
  margin: 0 auto;
  font-size: 2rem;
  background-size: 100%;
  background-repeat: no-repeat;
}
.color input{ background-color:#f1f1f1;}
.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
 
  content: "o arrastrar y suelta un archivo aquí";
  display: block;
  margin: 0 auto;
  color: #2ea591;

 
  text-align: center;
}

.form-select:disabled{
  background-color: #EEF0F3 !important;
}