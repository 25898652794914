

@each $color, $value in $theme-colors {
  @if $color == "warning" or $color == "light" {
    .btn-#{$color} {
      @include button-variant($value, $value);
      color: $black;
    }
    .btn-#{$color}:hover, .btn-#{$color}:focus{
      color: darken($value, 30%);
    }
    .btn-check:focus + .btn-#{$color}, .btn-#{$color}:active,.btn-#{$color}.active, .btn-check:focus + .btn-#{$color},  .btn-check:active + .btn-#{$color}, .show > .btn-#{$color}.dropdown-toggle {
      color: inherit;
    }

  }@else {
    .btn-#{$color} {
      @include button-variant($value, $value);
      color: $white;
    }
    .btn-#{$color}:hover, .btn-check:checked + .btn-#{$color}, .btn-check:active + .btn-#{$color}, .btn-#{$color}:active, .btn-#{$color}.active, .show > .btn-#{$color}.dropdown-toggle {
      color: $white;
    }
    .btn-check:focus + .btn-#{$color}, .btn-#{$color}:focus, .btn-check:focus + .btn-#{$color}, .btn-#{$color}:focus{
      color: $white;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
  .btn-outline-#{$color}:hover, .btn-outline-check:checked + .btn-outline-#{$color}, .btn-outline-check:active + .btn-outline-#{$color}, .btn-outline-#{$color}:active, .btn-outline-#{$color}.active, .show > .btn-outline-#{$color}.dropdown-toggle {
    color: $white;
  }
  .btn-outline-check:focus + .btn-outline-#{$color}, .btn-outline-#{$color}:focus, .btn-outline-check:focus + .btn-outline-#{$color}, .btn-outline-#{$color}:focus{
  
  }
  .btn-check:checked + .btn-outline-#{$color}, .btn-check:active + .btn-outline-#{$color}, .btn-outline-#{$color}:active, .btn-outline-#{$color}.active, .btn-outline-#{$color}.dropdown-toggle.show{
    color: $white;
  }

}

.btn-outline-light:hover, .btn-outline-check:checked + .btn-outline-light, .btn-outline-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .show > .btn-outline-light.dropdown-toggle, .btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show{
  color: $secondary;
}