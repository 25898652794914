//
// _table.scss
//

.table {
  border-color: $gray-200;
  border-width: 0.15rem;
  th {
    font-weight: $font-weight-bold;
    color: $gray-600;
    font-size: $font-size-sm;
    text-transform: uppercase;
    
  }
}



.table {
  th,
  td {
    white-space: nowrap !important;
   
  }
}

.table > tbody {
  th {

    border-right-color: $gray-200;
    border-right-width: 0.15rem;
    background-color:$gray-100;
  }
  // :first-child {

  //   border-top-left-radius:  0.25rem !important;
  // }
  // :last-child {
  //   border-top-right-radius: 0.25rem !important;
  // }
}

.table > :first-child > :last-child {
  tr, td, th {
    border-top-color: $gray-200;
    border-top-width: 0.05rem;
    border-bottom-color: $gray-200;
    border-bottom-width: 0.15rem;
    background-color:$gray-100;
  }
  // :first-child {

  //   border-top-left-radius:  0.25rem !important;
  // }
  // :last-child {
  //   border-top-right-radius: 0.25rem !important;
  // }
}



.table > :last-child > :first-child {
  tr, td {
    border-top-color: $gray-200;
    border-top-width: 0.10rem;
    background-color: $gray-100;

    border-bottom-color: $gray-200;
    border-bottom-width: 0.05rem;
  }
  // :first-child {

  //   border-bottom-left-radius:  0.25rem !important;
  // }
  // :last-child {
   
  //   border-bottom-right-radius: 0.25rem !important;
  // }
}