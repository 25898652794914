.opacity-0{
    opacity: 0;
}

.opacity-100{
    opacity: 100;
}


.fade {

    //opacity: 1;
    //transform: scale(0.95);
    transition: all 250ms  ease-in;
    //transition-delay: 300ms;
  
  }
  