//
// avatar.scss
//

.avatar-title {
  align-items: center;
  color: $white;
  display: flex;
  font-weight: $font-weight-bold;
  border: $border-width solid $border-color;
  justify-content: center;

}

.avatar-xs {
  height: 1rem;
  width: 1rem;
  font-size: 0.5rem;
}

.avatar-sm {
  height: 2rem;
  width: 2rem;
  font-size: 1rem;
}

.avatar-md {
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1.5rem;
}

.avatar-lg {
  height: 4rem;
  width: 4rem;
  font-size: 3rem;
}

.avatar-xl {
  height: 5rem;
  width: 5rem;
  font-size: 4rem;
}

.avatar-xxl {
  height: 7rem;
  width: 7rem;
  font-size: 6rem;
}


